<template>
  <v-container class="px-10" fluid>
    <!-- preview -->
    <v-dialog v-model="isPreview" max-width="600">
      <v-card style="padding: 20px">
        <v-card-title class="headline">
          Quick Preview
        </v-card-title>
        <v-tabs v-model="activeTab">
          <v-tab href="#en" aria-selected="false">English</v-tab>
          <v-tab href="#th" aria-selected="true">Thai</v-tab>
        </v-tabs>

        <v-card-title class="headline" style="margin-top: 25px">
          Terms & Conditions
        </v-card-title>

        <v-tabs-items v-model="activeTab">
          <v-tab-item key="en" value="en">
            <div
              class="content-detail"
              v-html="enContentObject.description"
            ></div>
          </v-tab-item>
          <v-tab-item key="th"  value="th">
            <div
              class="content-detail"
              v-html="thContentObject.description"
            ></div>
          </v-tab-item>
        </v-tabs-items>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="isPreview = false"
            large
            class="button-bold"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end of preview -->

    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="linkRoute"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <div class="d-flex justify-space-between">
          <h3 class="pageHeadline" t-data="page-headline">
            Terms and Conditions
          </h3>
          <v-btn
            id="add-btn"
            v-if="hasPermCreate()"
            large
            color="primary600Color"
            @click="create"
            class="white-text-button button-bold"
          >
            <v-icon left>mdi-plus-circle-outline</v-icon>Create Terms and
            Condition
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="isCreateSuccess"
      :class="{'snackbar-width': snackFullContent}"
      color="success200Color"
      top
      centered
    >
      <div class="d-flex flex-row align-center mt-0">
        <v-img
          src="/images/snackbar-success.png"
          aspect-ratio="1"
          snackbar-image
          class="mt-0 mr-2"
          :class="{
            'snackbar-image': 'isCreateSuccess'
          }"
        ></v-img>
        <p
          :class="{
            'snackbar-message sub-body-regular': 'isCreateSuccess',
            'mb-0': 'isCreateSuccess'
          }"
        >
          <template v-if="snackFullContent">{{ snackFullContent }}</template>
          <template v-else
            >Terms and Conditions has been {{ snackTitle }} successfully.</template
          >
        </p>
      </div>
    </v-snackbar>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="4"> </v-col>
          <v-col cols="12" sm="6"></v-col>
          <v-col cols="12" sm="2" class="d-flex justify-end align-center">
            <filter-dropdown @apply="handleApplyFilter"></filter-dropdown>
          </v-col>
        </v-row>
      </v-card-title>
      <v-layout fluid v-resize="onResize">
        <v-data-table
          t-data="data-table"
          :headers="headers"
          :items="termAndConditionList"
          :loading="isLoading"
          :options.sync="options"
          :items-per-page="pageSize"
          :server-items-length="total"
          :footer-props="{'items-per-page-options': itemsPerPage}"
          item-key="id"
          :sort-desc="true"
          class="elevation-1 custom-table"
          fixed-header
          style="width: 100%"
          :height="windowSize.y - heightOptions.header - heightOptions.tableFooter"
        >
           <template v-slot:item.type="{item}">
             {{ termConditionType(item.type) }}
           </template>
          <template v-slot:item.contents="{item}">
            <v-chip color="info200Color" @click="showDetail(item.contents, 'en')"
              >EN</v-chip
            >
            &nbsp;
            <v-chip color="info200Color" @click="showDetail(item.contents, 'th')"
              >TH</v-chip
            >
          </template>

          <template v-slot:item.orgs="{item}">{{ listBranch(item.orgs) }}</template>



          <template v-slot:item.version="{item}">
            {{
              item.major_version +
                '.' +
                item.minor_version +
                '.' +
                item.build_version
            }}
          </template>
          <template v-slot:item.create_date="{item}">{{
            dateFmt(item.create_date)
          }}</template>
          <template v-slot:item.update_by_user="{item}"><username-status-text :user="item.update_by_user" /></template>
        </v-data-table>
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import FilterDropdown from './FilterDropdown'
import {convertDate} from '@/helper/helper'
import {termConditionTypeText} from '@/constants/term-condition'
import UsernameStatusText from '@/components/UsernameStatusText'

export default {
  components: {
    'filter-dropdown': FilterDropdown,
    UsernameStatusText,
  },
  name: 'list',
  data() {
    return {
      heightOptions: {
        header: 308,
        tableFooter: 59,
      },
      windowSize: {
        x: 0,
        y: 0,
      },
      enContentObject: {},
      thContentObject: {},
      isPreview: false,
      isCreateSuccess: false,
      snackFullContent: '',
      snackTitle: 'created',
      options: {},
      page: 1,
      sortBy: 'create_date',
      sortDesc: false,
      pageSize: 25,
      search: '',
      activeTab: "th",
      linkRoute: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Terms and Conditions',
          disabled: true,
          href: '/term-and-conditions'
        }
      ],
      updateStatus: '',
      status: '',
      itemsPerPage: [25, 50, 100],
      headers: [
        {text: 'ID', align: 'left', value: 'id', sortable: false, width: 112},
        {text: 'Type', align: 'left', value: 'type', sortable: true, width: 272},
        {text: 'Content', align: 'left', value: 'contents', sortable: false, width: 237},
        {text: 'Branch', align: 'left', value: 'orgs', sortable: false, width: 600},
        {text: 'Version', align: 'left', value: 'version', sortable: true, width: 182},
        {
          text: 'App Version',
          align: 'left',
          value: 'app_version',
          sortable: true,
          width: 190
        },
        {
          text: 'Created Date/Time',
          align: 'left',
          value: 'create_date',
          sortable: true,
          width: 196
        },
        {text: 'Created By', align: 'left', value: 'update_by_user', sortable: false, width: 162},
      ]
    }
  },
  computed: {
    load: function() {
      return this.$store.state.news.load
    },
    items: function() {
      return this.$store.state.news.items
    },
    totalItem: function() {
      return this.$store.state.news.total
    },
    ...mapState('termAndCondition', [
      'termAndConditionList',
      'isLoading',
      'total'
    ])
  },
  async mounted() {
    let query = this.$route.query.isCreateSuccess
    let querySnackTitle = this.$route.query.snackTitle
    if (query) {
      this.$router.replace({})
      this.isCreateSuccess = query
      this.snackTitle = querySnackTitle
    }
  },
  beforeRouteLeave(to, from, next) {
    this.resetList()
    next()
  },
  methods: {
    onResize () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    termConditionType(type) {
      return termConditionTypeText[type]
    },
    onPreview() {
      this.isPreview = true
    },
    handleApplyFilter() {
      this.options.page = 1
    },
    dateFmt(date) {
      return convertDate(date)
    },
    selectStatus() {
      this.setFilterStatus(this.statusSelect)
      this.fetch()
    },
    showDetail(contents, code) {
      this.enContentObject = contents.filter(obj => {
        return obj.code == 'en'
      })[0]
      this.thContentObject = contents.filter(obj => {
        return obj.code == 'th'
      })[0]
      this.isPreview = true
      this.activeTab = code
    },
    hasPermCreate() {
      return true
      // return hasPermision('CanAddNews')
    },
    create() {
      this.$router.push({name: 'term-and-condition-create'})
    },
    filter() {
      this.options.page = 1
      this.page = 1
      this.selected = []
      this.fetch(this.page)
    },
    listBranch(orgs) {
      return orgs.map(org => org.org_codename.toUpperCase()).join(', ')
    },
    ...mapActions('termAndCondition', [
      'fetch',
      'setPage',
      'setPageSize',
      'setFilterType',
      'setFieldSort',
      'setSort',
      'resetList'
    ])
  },
  watch: {
    isCreateSuccess() {
      if (!this.isCreateSuccess) this.snackFullContent = ''
    },
    options: {
      handler() {
        const {sortBy, sortDesc, page, itemsPerPage} = this.options
        this.setPage(page)
        this.setFieldSort(sortBy[0])
        this.setPageSize(itemsPerPage)
        this.setSort(sortDesc[0])
        this.fetch()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.snackbar-width::v-deep > .v-snack__wrapper {
  min-width: 400px;
}
.headline {
  font-weight: 700 !important;
}
.v-dialog > .v-card > .v-card__title {
  padding: 0;
}
.v-card__title {
  padding-left: 0;
}
.v-tabs:not(.v-tabs--vertical) .v-tab {
  font-weight: bold !important;
  text-transform: capitalize !important;
}
.content-detail {
  padding-top: 15px;
  padding-bottom: 15px;
}
</style>
