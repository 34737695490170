<template>
  <filter-button
    :count-filtered="countFiltered"
    :disabled-clear="!isClearFilter"
    :disabled-apply="!isChangeFilter"
    @cancel="handleCancelFilter"
    @clear="handleClearFilter"
    @apply="handleSearchFilter"
  >
    <template v-slot:content>
      <v-list-item class="mt-2 ml-5 mr-5 fixWidth d-block">
        <p class="filterContentTitle sub-body-bold mb-0" t-data="type-title">Types</p>
        <v-select
          class="can-disable-selector selector-filter"
          t-data="type-selector"
          placeholder="Select Type"
          :items="typeList"
          item-text="value"
          item-value="id"
          outlined
          required
          color="primary500Color"
          dense
          v-model="type"
        ></v-select>
      </v-list-item>
    </template>
  </filter-button>
</template>

<script>
import {mapActions} from 'vuex'
import FilterButton from '@/components/FilterButton'
import {termConditionTypeEnum, termConditionTypeText} from '@/constants/term-condition'
var _ = require('lodash')

export default {
  name: 'FilterDropdown',
  data: () => ({
    countFiltered: 0,
    appliedType: null,
    type: null,
  }),
  components: {
    FilterButton
  },
  computed: {
    typeList() {
      return _.map(termConditionTypeEnum, (type) => {
        return {id: type, value: termConditionTypeText[type]}
      })
    },
    isClearFilter() {
      const changedType = !_.isEqual(this.type, null)
      return changedType;
    },
    isChangeFilter() {
      const changedType = !_.isEqual(this.type, this.appliedType)
      return  changedType;
    }
  },
  methods: {
    sumFiltered() {
      return this.type === null ? 0 : 1
    },
    handleSearchFilter() {
      this.$emit('apply')
      this.appliedType = this.type
      this.setFilterType(this.appliedType)
      this.fetch()
      this.countFiltered = this.sumFiltered()
    },
    handleClearFilter() {
      this.type = null
    },
    handleCancelFilter() {
      this.type = this.appliedType;
      this.setFilterType(this.type)
      this.countFiltered = this.sumFiltered()
    },
    ...mapActions('termAndCondition', ['fetch', 'setFilterType'])
  }
}
</script>
